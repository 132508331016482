const SHOP_DATA = [
  {
    id: 1,
    title: 'Orange-Mango Scented Candle',
    description:
      'Our Tropical Orange-Mango Scented Candle Consist Of Soy Wax As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Orange Slice And Golden Flakes.',
    imageUrl: [
      'https://i.postimg.cc/7LMMKMnB/DSC00639.jpg',
      'https://i.postimg.cc/Gm3ycwFj/DSC00631.jpg',
    ],
    price: 17.99,
  },
  {
    id: 2,
    title: 'Coco-Lime Scented Candle',
    description:
      'Our Tropical Coco-Lime Scented Candle Consist Of Soy Wax As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Spring Flower And Golden Flakes.',
    imageUrl: [
      'https://i.postimg.cc/m2LD93PL/DSC00640.jpg',
      'https://i.postimg.cc/P5cr0B2J/DSC00644.jpg',
    ],
    price: 17.99,
  },
  {
    id: 3,
    title: 'Strawberry-Lemon Scented Candle',
    description:
      'Our Tropical Strawberry-Lemon Scented Candle Consist Of Soy Wax As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Spring And Rose Petals With Golden Flakes.',
    imageUrl: [
      'https://i.postimg.cc/j2Jh4Vgf/DSC00670.jpg',
      'https://i.postimg.cc/YCxTWgmH/DSC00671.jpg',
    ],
    price: 17.99,
  },
  {
    id: 4,
    title: 'Strawberry-Peach Scented Candle',
    description:
      'Our Tropical Strawberry-Peach Scented Candle Consist Of Soy Wax As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Peach Rose With Golden Flakes.',
    imageUrl: [
      'https://i.postimg.cc/6qNhcjD1/DSC00712.jpg',
      'https://i.postimg.cc/7Pg9h4zv/DSC00710.jpg',
    ],
    price: 17.99,
  },
  {
    id: 5,
    title: 'WildBerry-Lime Scented Candle',
    description:
      'Our Tropical WildBerry-Lime Scented Candle Consist Of Soy Wax As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Guava.',
    imageUrl: [
      'https://i.postimg.cc/xT8X7yns/DSC00823.jpg',
      'https://i.postimg.cc/MG2XvCR4/DSC00825.jpg',
    ],
    price: 17.99,
  },
  {
    id: 6,
    title: 'WildBerry-Lime Scented BodyButter',
    description:
      'Our Tropical WildBerry-Lime Scented BodyButter Consist Of Shea And Coco Butter As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Guava And Rose Petals.',
    imageUrl: [
      'https://i.postimg.cc/wjK9VhvR/DSC00764.jpg',
      'https://i.postimg.cc/PrDjPP4X/DSC00763.jpg',
    ],
    price: 12.99,
  },
  {
    id: 7,
    title: 'Coco-Lime Scented BodyButter',
    description:
      'Our Tropical Coco-Lime Scented BodyButter Consist Of Shea And Cocoa Butter As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Spring Rose Petals.',
    imageUrl: [
      'https://i.postimg.cc/jjMVGNsX/DSC00765.jpg',
      'https://i.postimg.cc/Kz4XFzjy/DSC00769.jpg',
    ],
    price: 12.99,
  },
  {
    id: 8,
    title: 'Orange-Mango Scented BodyButter',
    description:
      'Our Tropical Orange-Mango Scented BodyButter Consist Of Shea And Cocoa Butter As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Spring Mango Petals.',
    imageUrl: [
      'https://i.postimg.cc/4x5NxYfK/DSC00781.jpg',
      'https://i.postimg.cc/PxLtyKmZ/DSC00788.jpg',
    ],
    price: 12.99,
  },
  {
    id: 9,
    title: 'Strawberry-Peach Scented BodyButter',
    description:
      'Our Tropical Strawberry-Peach Scented BodyButter Consist Of Shea And Cocoa Butter As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Spring Peach Petals.',
    imageUrl: [
      'https://i.postimg.cc/TPsxKmZP/DSC00797.jpg',
      'https://i.postimg.cc/QCGrbDNG/DSC00802.jpg',
    ],
    price: 12.99,
  },
  {
    id: 10,
    title: 'Strawberry-Lemon Scented BodyButter',
    description:
      'Our Tropical Strawberry-Lemon Scented BodyButter Consist Of Shea And Cocoa Butter As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Spring And Rose Petals.',
    imageUrl: [
      'https://i.postimg.cc/XNcPhdQy/DSC00811.jpg',
      'hhttps://i.postimg.cc/4NWS4ZCf/DSC00816.jpg',
    ],
    price: 12.99,
  },
  {
    id: 11,
    title: 'Orange-Spice Scented Wax Melt',
    description:
      'Our Tropical Orange-Spice Scented Wax Melt Consist Of Organic Gel Wax As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Orange Slice And Gold Flakes.',
    imageUrl: [
      'https://i.postimg.cc/tJ22gdMS/DSC00880.jpg',
      'https://i.postimg.cc/2890Rwv3/DSC00876.jpg',
    ],
    price: 8.99,
  },
  {
    id: 12,
    title: 'Coco-Lime Scented Wax Melt',
    description:
      'Our Tropical Coco-Lime Scented Wax Melt Consist Of Organic Gel Wax As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Lemon Petals And Gold Flakes.',
    imageUrl: [
      'https://i.postimg.cc/hG1xgDwM/DSC00895.jpg',
      'https://i.postimg.cc/7YJ2hMd5/DSC00899.jpg',
    ],
    price: 8.99,
  },
  {
    id: 13,
    title: 'Wildberry-Lime Scented Wax Melt',
    description:
      'Our Tropical Wildberry-Lime Scented Wax Melt Consist Of Organic Gel Wax As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Rose Petals And Guava Slice.',
    imageUrl: [
      'https://i.postimg.cc/tTfgG8X2/DSC00912.jpg',
      'https://i.postimg.cc/vDwHc9xn/DSC00911.jpg',
    ],
    price: 8.99,
  },
  {
    id: 14,
    title: 'Strawberry-Lemon Scented Wax Melt',
    description:
      'Our Tropical Strawberry-Lemon Scented Wax Melt Consist Of Organic Gel Wax As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Rose Petals And Guava Slice.',
    imageUrl: [
      'https://i.postimg.cc/BZkpQCNz/DSC00932.jpg',
      'https://i.postimg.cc/2Sfxn8sF/DSC00928.jpg',
    ],
    price: 8.99,
  },
  {
    id: 15,
    title: 'Strawberry-Peach Scented Wax Melt',
    description:
      'Our Tropical Strawberry-Peach Scented Wax Melt Consist Of Organic Gel Wax As Its Base. With Premium Produced Scented Fragrance. Topped Off With Aromatherapy Dried Up Rose Petals And Gold Flakes.',
    imageUrl: [
      'https://i.postimg.cc/7Lt7txWR/DSC00948.jpg',
      'https://i.postimg.cc/T2DmQvqk/DSC00942.jpg',
    ],
    price: 8.99,
  },
];

export default SHOP_DATA;

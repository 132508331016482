import React from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import './main.scss';
const Layout = ({children}) => {
  return (
    <>
      <Header />
      <main className='main-container'>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;

import React, {useContext} from 'react';
import {CartContext} from '../../context/cart-context';

import Layout from '../shared/layout';

// import ShippingAddress from './custom-checkout/shipping-address';
// import CustomCheckout from './custom-checkout/custom-checkout';

import './checkout.styles.scss';
import StripeCheckout from './stripe-checkout/stripe-checkout';

const Checkout = () => {
  // const {itemCount, total, cartItems} = useContext(CartContext);
  const {itemCount, total} = useContext(CartContext);

  // const [shipping, setShipping] = useState(null);
  // const addressShown = {
  //   display: shipping ? 'none' : 'block',
  // };
  // const cardShown = {
  //   display: shipping ? 'block' : 'none',
  // };
  return (
    <Layout>
      <div className='checkout check-container'>
        <h2>Checkout Summary</h2>
        <h3>{`Total Items: ${itemCount}`}</h3>
        <div className='checkout-sum'>
          <span>{`Amount : $${total.toFixed(2)}`}</span>
        </div>
        <StripeCheckout />
        {/* <div style={addressShown}>
          <ShippingAddress setShipping={setShipping} />
        </div> */}
        {/* <div style={cardShown}>
          <CustomCheckout {...{shipping, cartItems}} />
        </div> */}
      </div>
    </Layout>
  );
};

export default Checkout;

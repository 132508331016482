import React from 'react';
import './footer.styles.scss';
import mdgdevelop from '../../assets/mdgdevelop.jpg';
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <div className='footer'>
        <a href='https://mdgdevelop.com/'>
          <img alt='mdgdevelop' src={mdgdevelop} />
        </a>
        {year} © TPARADISE
      </div>
      ;
    </>
  );
};

export default Footer;

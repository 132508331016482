import './App.scss';
import React from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
import AboutUs from './components/about-us/about-us';
import HomePage from './components/home-page';
import NotFound from './components/not-found';
import Shop from './components/pages/shop/shop';
import SingleProduct from './components/single-product/single-product';
import CartPage from './components/pages/cart-page/cart-page';
import Checkout from './components/checkout/checkout';
import success from './components/checkout/stripe-checkout/success';
import canceled from './components/checkout/stripe-checkout/canceled';
import SignUp from './components/sign-up/sign-up';
import SignIn from './components/sign-in/sign-in';

function App() {
  const {pathname} = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className='App'>
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route path='/shop' component={Shop} />
        <Route path='/product/:id' component={SingleProduct} />
        <Route path='/cart' component={CartPage} />
        <Route path='/checkout' component={Checkout} />
        <Route path='/sign-up' component={SignUp} />
        <Route path='/sign-in' component={SignIn} />
        <Route path='/about-us' component={AboutUs} />
        <Route path='/success' component={success} />
        <Route path='/canceled' component={canceled} />
        <Route path='*' component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;

import React, {useContext, useState} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import {CartContext} from '../../../context/cart-context';
import {fetchFromAPI} from '../../../helpers';
import {UserContext} from '../../../context/user-context';
import {withRouter} from 'react-router-dom';
import validator from 'validator';
import Loader from './../../Loader';
const StripeCheckout = ({history}) => {
  const {user} = useContext(UserContext);
  const [spinner, setSpinner] = useState(false);
  const [email, setEmail] = useState('');
  const {cartItems} = useContext(CartContext);
  const stripe = useStripe();

  const handleGuestCheckout = async (e) => {
    setSpinner(true);
    e.preventDefault();
    // if (email === '') {
    //   console.log(cartItems);
    //   setSpinner(false);
    //   return;
    // }
    if (validator.isEmail(email) || email === '') {
    } else {
      setSpinner(false);
      return;
    }
    const line_items = cartItems.map((item) => {
      return {
        quantity: item.quantity,
        price_data: {
          currency: 'usd',
          unit_amount: (item.price * 100).toFixed(0), // amount is in cents
          product_data: {
            name: item.title,
            description: item.description,
            images: [item.imageUrl[0]],
          },
        },
      };
    });
    const response = await fetchFromAPI('create-checkout-session', {
      body: {line_items, customer_email: email},
    });

    const {sessionId} = response;
    //if transaction is successfull were not gonna get an object back but
    //if its not will get an error object
    const {error} = await stripe.redirectToCheckout({
      sessionId,
    });

    if (error) {
    }
    setSpinner(false);
  };

  return (
    <form className='form' onSubmit={handleGuestCheckout}>
      {user === null && (
        <div>
          <div>
            <input
              type='email'
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
              value={email}
              className='nomad-input'
            />
          </div>

          <div className='submit-btn'>
            <button
              type='submit'
              className='button is-black nomad-btn submit guest-button'
            >
              Checkout as Guest
            </button>
          </div>

          <span>Already a member?</span>
          <button
            onClick={() => history.push(`/sign-in`)}
            className='button is-black nomad-btn submit guest-button'
          >
            Sign In
          </button>
          <span>Not a member?</span>
          <button
            onClick={() => history.push(`/sign-up`)}
            className='button is-black nomad-btn submit guest-button'
          >
            Sign Up
          </button>
        </div>
      )}
      {user !== null && (
        <div>
          <div className='submit-btn'>
            <button
              onClick={() => setEmail(user.email)}
              style={{width: '22rem'}}
              type='submit'
              className='button is-black nomad-btn submit'
            >
              Checkout as {user.email}
            </button>
          </div>
        </div>
      )}
      <div className='spinner'>
        <Loader show={spinner} />
      </div>
    </form>
  );
};

export default withRouter(StripeCheckout);

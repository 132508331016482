import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; // for the db
import 'firebase/compat/auth';

const config = {
  apiKey: 'AIzaSyCnjgJBCPUoPNJyQZWAZEH-of7g0b6Zt0k',
  authDomain: 'react-store1.firebaseapp.com',
  projectId: 'react-store1',
  storageBucket: 'react-store1.appspot.com',
  messagingSenderId: '846605113701',
  appId: '1:846605113701:web:7aa3ca4295f54b805f298c',
};

firebase.initializeApp(config);

const firestore = firebase.firestore();
const auth = firebase.auth();

const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) {
    return;
  }

  const userRef = firestore.doc(`users/${userAuth.uid}`); //users/uniq26535
  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const {displayName, email} = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      // console.log('error creating user', error.message);
    }
  }

  return userRef;
};

export {firestore, createUserProfileDocument, auth};

import React from 'react';
import {withRouter} from 'react-router-dom';
import studioBag from '../../assets/logo-pic.JPG';
import './main-section.styles.scss';

const MainSection = ({history}) => {
  return (
    <div className='main-section-container'>
      <div className='main-section-middle'>
        <div className='ms-m-image'>
          <img src={studioBag} alt='studio bag' />
        </div>
        <div className='ms-m-description'>
          <h2>
            Tparadise candle cosmetics homebase goal is to provide natural
            organic candles with less toxic carbon soot that circulates through
            your house.
          </h2>
          <p>
            Our tropical scents can fill your home with delight and can deliver
            a fresh tropical enviroment.
          </p>
          <button
            className='button is-black'
            id='shop-now'
            onClick={() => history.push('/shop')}
          >
            SEE ALL PRODUCTS
          </button>
        </div>
      </div>
    </div>
  );
};
export default withRouter(MainSection);

import React from 'react';
import Layout from '../shared/layout';
import '../about-us/about-us.scss';
const AboutUs = () => {
  return (
    <>
      <Layout>
        <div className='us-container'>
          <h1>About Us</h1>
          <span>
            Tparadise cosmetics focuses on the prompt goal to produce
            cruelty-free candles. We believe that we have a chance to make
            candles without having to use unnecessary toxic chemicals to provide
            tropical scents for any home or environment. Our team believes we
            have a chance to make fully organic and naturally scented candles
            without having the risks that affect your respiratory health. We can
            assure you that not only you will be breathing into fresh organic
            scents, we will also make sure to provide your home with less carbon
            soot that's often found in regular candles. Tparadise thanks you for
            checking out our website today and learning more about what we enjoy
            producing in our business. We welcome you to check out our organic
            products and also to send reviews for more productivity. Thank You!
          </span>
          <span style={{marginTop: '1rem'}}>
            You can send an email at <strong>tparadiseessants@gmail.com</strong>{' '}
            for reviews, big orders or any question that you have.
          </span>
        </div>
      </Layout>
    </>
  );
};

export default AboutUs;

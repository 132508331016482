import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import CartIcon from '../cart-icon/cart-icon';
import {auth} from '../../firebase';
import {UserContext} from '../../context/user-context';
import './header.styles.scss';
import logo from '../../assets/tparadise.jpeg';
const Header = () => {
  const [menuOpen, toggleMenu] = React.useState(false);
  const {user} = useContext(UserContext);
  return (
    <React.Fragment>
      <nav className='nav-menu container'>
        {/* <div className='logo'>
        <Link to='/'>
          <div className='logo-container'>
           
          </div>
        </Link>
      </div> */}
        <ul className='nav-ul'>
          <li>
            <Link to='/'>
              <div className='logo-container'>
                <img className='t-logo' alt='tparadise' src={logo} />
              </div>
            </Link>
          </li>
          {/* <li>
          <Link to='/'>Home</Link>
        </li> */}
          <li className='pc-view'>
            <Link to='/about-us'>About Us</Link>
          </li>
          <li className='pc-view'>
            <Link to='/shop'>Shop</Link>
          </li>
          {!user && (
            <li className='pc-view'>
              <Link to='/sign-in'>Sign In</Link>
            </li>
          )}
          {user && (
            <li className='pc-view' onClick={() => auth.signOut()}>
              Sign Out
            </li>
          )}
          {!user && (
            <li className='pc-view'>
              <Link to='/sign-up'>Sign Up</Link>
            </li>
          )}
          <li>
            <CartIcon />
          </li>
        </ul>

        {/* <i className='cart-container fas fa-shopping-cart fa-lg'></i> */}

        <i
          onClick={() => toggleMenu(!menuOpen)}
          className={
            'menu-icon pc-view-false ' +
            (menuOpen ? 'fas fa-times ' : 'fas fa-bars ') +
            'fa-lg'
          }
        ></i>
      </nav>
      {menuOpen && (
        <div className='mobile-menu-container'>
          <ul>
            <li>
              <Link to='/about-us'>About Us</Link>
            </li>
            <li>
              <Link to='/shop'>Shop</Link>
            </li>
            {!user && (
              <li>
                <Link to='/sign-in'>Sign In</Link>
              </li>
            )}
            {user && <li onClick={() => auth.signOut()}>Sign Out</li>}
            {!user && (
              <li>
                <Link to='/sign-up'>Sign Up</Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

export default Header;

import React from 'react';
import './hero.styles.scss';
import {Carousel} from 'react-bootstrap';

const Hero = () => {
  return (
    <Carousel>
      <Carousel.Item interval={4000}>
        <div className='img-cont'>
          <img
            className='d-block w-100'
            src='https://i.postimg.cc/wxf54QSv/DSC00725-2.jpg'
            alt='First slide'
          />
        </div>
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={4000}>
        <div className='img-cont'>
          <img
            className='d-block w-100'
            src='https://i.postimg.cc/KzcLJ1fB/DSC00611.jpg'
            alt='Second slide'
          />
        </div>
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className='img-cont'>
          <img
            className='d-block w-100'
            src='https://i.postimg.cc/m219XfYX/DSC00842.jpg'
            alt='Third slide'
          />
        </div>
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    // <section className='hero is-large is-info hero-image'>
    //   <div className='hero-body'>
    //     <div className='container'>
    //       <p className='hero-title'>Bags Reaimagined for modern life.</p>
    //       <div className='shop-now-btn'>
    //         <button className='button is-black' id='shop-now'>
    //           SHOP NOW
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default Hero;
